import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;

  constructor(public dialog: MatDialog, private router: Router) {}

  navigateToState(type) {
    if (this.navigationStateIncludesCurrentUrl(type, this.router.url)) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([type]);
      });
    } else {
      this.router.navigate([type]);
    }
  }

  navigationStateIncludesCurrentUrl(type: string, url: string): boolean {
    let currentUrl = url.substring(1);
    if (currentUrl.indexOf('/') !== -1) {
      currentUrl = currentUrl.substring(0, currentUrl.indexOf('/'));
    }
    return type.includes(currentUrl);
  }
}
