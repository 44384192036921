import { Injectable, InjectionToken } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IEntity } from '../models/base-entity';
import { ActionType, AvailableActions, ModelType } from '../models/permission.model';

export interface ModelConfig {
  modelType: ModelType;
  params?: string | string[];
}
export const MODEL_CONFIG = new InjectionToken<ModelConfig>('MODEL_CONFIG');

@Injectable()
export class ModelEditorService<TModel extends IEntity> {

  private _model: TModel;
  private _modelConfig: ModelConfig;

  public set model(value: TModel|null) {
    this._model = value;
    this.modelChange.next(value);
  }
  public get model(): TModel|null {
    return this._model;
  }

  public set modelConfig(value: ModelConfig) {
    this._modelConfig = value;
  }
  public get modelConfig(): ModelConfig {
    return this._modelConfig;
  }

  /**
   * A jelenlegi elemet most hozzuk-e létre
   */
  public get isCreating(): boolean {
    return !this._model || !this._model.id;
  }

  /**
   * Egy létező elemet szerkesztünk-e
   */
  public get isEditing(): boolean {
    return !!this._model?.id;
  }

  public set action(value: ActionType.View | ActionType.Create | ActionType.Update) {
    const action = `${this._modelConfig.modelType}_${value}` as AvailableActions;
    this.actionChange.next(action);
  }

  /**
   * Lefut minden esetben ha a model megváltozik
   */
  public readonly modelChange = new ReplaySubject<TModel>();
  public readonly actionChange = new ReplaySubject<AvailableActions>();
}
