import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class UserService {

  user: User = null;
  constructor(private http: HttpClient, private router: Router) {
    if (window.localStorage.getItem(environment.userKey) != null) {
      this.user = JSON.parse(window.localStorage.getItem(environment.userKey));
    }
  }

  login(loginData: any): Observable<any> {
    const data = Object.assign({
      'client_id': environment.clientId,
      'client_secret': environment.clientSecret,
    }, loginData);

    return this.http.post(environment.tokenURL, data).pipe(tap((res) => {
      this.saveData(res);
    }));
  }

  logout() {
    window.localStorage.removeItem(environment.userKey);
    window.localStorage.removeItem(environment.accessTokenKey);
    window.localStorage.removeItem(environment.refreshTokenKey);

    this.user = null;
    this.router.navigateByUrl('/sessions/signin');
  }

  getToken() {
    return window.localStorage.getItem(environment.accessTokenKey);
  }

  getRefreshToken() {
    return window.localStorage.getItem(environment.refreshTokenKey);
  }

  saveData(data) {
    this.user = data.user;
    window.localStorage.setItem(environment.accessTokenKey, data.access_token);
    window.localStorage.setItem(environment.userKey, JSON.stringify(data.user));
    window.localStorage.setItem(environment.refreshTokenKey, data.refresh_token);
  }
}
