import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesService } from './language/languages.service';
import { Language } from '../models/language.model';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/icon_action/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  name_key?: string; // Language key for name
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  tooltip_key?: string; // Language key form tooltip
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  action?: number; // See the actions list in SidenavComponent
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  name_key: string;
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
  tooltip?: string; // Tooltip text
  tooltip_key?: string; // Language key form tooltip
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  plainMenu: IMenuItem[] = [
    {
      name: this.translateService.instant('NAVIGATION.BUTTON1'),
      name_key: 'NAVIGATION.BUTTON1',
      type: 'icon',
      tooltip: this.translateService.instant('NAVIGATION.BUTTON1'),
      tooltip_key: 'NAVIGATION.BUTTON1',
      icon: 'person_add',
      state: 'users/0',
    },
    {
      name: this.translateService.instant('NAVIGATION.BUTTON2'),
      name_key: 'NAVIGATION.BUTTON2',
      type: 'icon',
      tooltip: this.translateService.instant('NAVIGATION.BUTTON2'),
      tooltip_key: 'NAVIGATION.BUTTON2',
      icon: 'add_to_photos',
      state: 'assets/0',
    },
    {
      name: this.translateService.instant('NAVIGATION.BUTTON3'),
      name_key: 'NAVIGATION.BUTTON3',
      type: 'icon',
      tooltip: this.translateService.instant('NAVIGATION.BUTTON3'),
      tooltip_key: 'NAVIGATION.BUTTON3',
      icon: 'group_add',
      state: 'groups/0',
    },
    {
      name: this.translateService.instant('NAVIGATION.USERS'),
      name_key: 'NAVIGATION.USERS',
      type: 'link',
      tooltip: this.translateService.instant('NAVIGATION.USERS'),
      tooltip_key: 'NAVIGATION.USERS',
      icon: 'school',
      state: 'users'
    },
    {
      name: this.translateService.instant('NAVIGATION.SCHOOLS'),
      name_key: 'NAVIGATION.SCHOOLS',
      type: 'link',
      tooltip: this.translateService.instant('NAVIGATION.SCHOOLS'),
      tooltip_key: 'NAVIGATION.SCHOOLS',
      icon: 'home',
      state: 'schools'
    },
    {
      name: this.translateService.instant('NAVIGATION.SCHOOLS_ADMISSION'),
      name_key: 'NAVIGATION.SCHOOLS_ADMISSION',
      type: 'link',
      tooltip: this.translateService.instant('NAVIGATION.SCHOOLS_ADMISSION'),
      tooltip_key: 'NAVIGATION.SCHOOLS_ADMISSION',
      icon: 'transfer_within_a_station',
      state: 'schools-admission'
    },
    {
      type: 'separator'
    },
    {
      name: this.translateService.instant('NAVIGATION.TAGS'),
      name_key: 'NAVIGATION.TAGS',
      type: 'link',
      tooltip: this.translateService.instant('NAVIGATION.TAGS'),
      tooltip_key: 'NAVIGATION.TAGS',
      icon: 'assignment',
      state: 'tags'
    },
    {
      name: this.translateService.instant('NAVIGATION.GROUPS'),
      name_key: 'NAVIGATION.GROUPS',
      type: 'link',
      tooltip: this.translateService.instant('NAVIGATION.GROUPS'),
      tooltip_key: 'NAVIGATION.GROUPS',
      icon: 'people',
      state: 'groups'
    },
    {
      type: 'separator'
    },
    {
      name: this.translateService.instant('NAVIGATION.ASSETS'),
      name_key: 'NAVIGATION.ASSETS',
      type: 'link',
      tooltip: this.translateService.instant('NAVIGATION.ASSETS'),
      tooltip_key: 'NAVIGATION.ASSETS',
      icon: 'compare',
      state: 'assets'
    },
    {
      name: this.translateService.instant('NAVIGATION.MARKERS'),
      name_key: 'NAVIGATION.MARKERS',
      type: 'link',
      tooltip: this.translateService.instant('NAVIGATION.MARKERS'),
      tooltip_key: 'NAVIGATION.MARKERS',
      icon: 'push_pin',
      state: 'markers'
    }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = this.translateService.instant('NAVIGATION.ICON_MENU_TITLE');
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.plainMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.

  private languageChange: Subscription;
  constructor(private translateService: TranslateService, private languagesService: LanguagesService) {
    this.languageChange = this.languagesService.languageChange().subscribe(
      (lang: Language|null) => {
        this.iconTypeMenuTitle = this.translateService.instant('NAVIGATION.ICON_MENU_TITLE');
        this.applyLanguageChange(this.plainMenu);
      }
    );
  }

  publishNavigationChange(menuType: string) {
    // switch (menuType) {
    //   case 'separator-menu':
    //     this.menuItems.next(this.separatorMenu);
    //     break;
    //   case 'icon-menu':
    //     this.menuItems.next(this.iconMenu);
    //     break;
    //   default:
    //     this.menuItems.next(this.plainMenu);
    // }
  }

  applyLanguageChange(menu: IMenuItem[]|IChildItem[]) {
    if (menu) {
      for (let index = 0; index < menu.length; index++) {
        if (menu[index].name && menu[index].name_key) {
          menu[index].name = this.translateService.instant(menu[index].name_key);
        }
        if (menu[index].tooltip && menu[index].name_key) {
          menu[index].tooltip = this.translateService.instant(menu[index].tooltip_key);
        }

        if (menu[index].sub) {
          this.applyLanguageChange(menu[index].sub);
        }
      }
    }
  }
}
