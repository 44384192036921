<div *ngIf="_config != null">
  <form [formGroup]="form">
    <mat-form-field *ngFor="let input of _config" class="mr-12">
      <div [ngSwitch]="input.type">
        <div *ngSwitchCase="1">
          <input matInput [placeholder]="input.placeholder" [formControlName]="input.name">
        </div>
        <div *ngSwitchCase="2">
          <mat-select [formControlName]="input.name" [placeholder]="input.placeholder">
            <mat-option>{{ "OPTIONS.ALL" | translate }}</mat-option>            
            <mat-option *ngFor="let option of input.options" [value]="option.value">
              {{ option.name_key | translate }}
            </mat-option>
          </mat-select>          
        </div>
        <div *ngSwitchDefault>
          <input matInput [placeholder]="input.placeholder" [formControlName]="input.name">
        </div>
      </div>
    </mat-form-field>      
  </form>
</div>
