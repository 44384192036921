import { Injectable } from '@angular/core';
import { ListService } from './list/list.service';
import { Group } from '../models/group.model';

/*
* A csoportokkal kapcsolatos szerver kommunikációért felel
*/
@Injectable()
export class GroupsService extends ListService<Group> {
  url: string = 'groups';
}
