<button
  mat-button
  class="button-loading {{ btnClass }}"
  [type]="type"
  [disabled]="loading"
  [color]="color"
  [ngClass]="{
    loading: loading,
    'mat-button': !raised,
    'mat-raised-button': raised
  }">
  <span class="btn-spinner" *ngIf="loading"></span>
  <span *ngIf="!loading">
      <ng-content></ng-content>
    </span>
  <span *ngIf="loading">{{ loadingText }}</span>
</button>
