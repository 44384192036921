import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'eduardoDate' })
export class DatePipe implements PipeTransform {

  transform(value: string) {
    return moment(value).format('YYYY.MM.DD. HH:mm');
  }

}
