import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// Szűrő típusok
export const TableFilterType = {
  TEXT: 1,
  SELECT: 2,  // options property-be kell átadni az option-öket
}

/*
* A komponens a config paraméterben kapott szűrő inputokat jeleníti meg-
* A változást pedig a changed callbackben jelzi vissza.
*/
@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements OnInit, OnDestroy {

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @Input() debounceTime = 1000;

  @Input('config')
  set config(inputs: any[]) {
    if (inputs) {
      const controls = {};
      for (const input of inputs) {
        controls[input.name] = new FormControl(input.value);
      }
      this.form = this.fb.group(controls);
      this._config = inputs;
    }
  }

  _config: any[] = null;
  get config(): any[] {
    return this._config;
  }

  form: FormGroup;
  sub: Subscription;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.sub = this.form.valueChanges.pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged())
          .subscribe((filter) => {
            this.changed.emit(filter);
        });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
