import { Injectable } from '@angular/core';
import { Asset, AssetNote, AssetNoteStatus } from '../models/asset.model';
import { environment } from 'environments/environment';
import { ListService } from './list/list.service';
import { Observable } from 'rxjs';
import { ItemResponse } from '../models/api/item.response';
/*
* A tartalmakkal kapcsolatos szerver kommunikációért felel
*/
@Injectable()
export class AssetsService extends ListService<Asset> {
  url: string = 'assets';

  clone(originalAssetId: number) {
    return this.http.post(environment.apiURL + this.url + '/' + originalAssetId + '/clone', null);
  }

  saveMarkerMeta(assetId: number, data: { marker_id: number, position: number[], rotate: number[], scale: number }) {
    return this.http.post(environment.apiURL + this.url + '/' + assetId + '/markers', data);
  }

  saveAssetNote(data: {
    asset_id: number,
    title: string,
    content: string,
    status: AssetNoteStatus,
    meta: { anchor?: number[], position: number[], interval: number[] }
  }): Observable<ItemResponse<AssetNote>> {
    return this.http.post<ItemResponse<AssetNote>>(environment.apiURL + this.url + '/notes', data);
  }

  updateAssetNote(assetNoteId: number, data: {
    title: string,
    content: string,
    status: AssetNoteStatus,
    meta: { anchor?: number[], position: number[], interval: number[] }
  }): Observable<ItemResponse<AssetNote>> {
    return this.http.put<ItemResponse<AssetNote>>(environment.apiURL + this.url + '/notes/' + assetNoteId, data);
  }

  deleteAssetNote(assetNoteId: number) {
    return this.http.delete(environment.apiURL + this.url + '/notes/' + assetNoteId);
  }
}
