import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { Subscription } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { ILayoutConf, LayoutService } from 'app/shared/services/layout.service';
import { UserService } from 'app/shared/services/user.service';
import { environment } from 'env/environment';
import { version } from 'root/package.json';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html',
  styles: [`
    .sidebar-version {
      position: absolute;
      width: 100%;
      bottom: 15px;
      text-align: center;
      color: white;
    }`
  ]
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  public environment = environment;
  public version = version;
  constructor(
      public navService: NavigationService,
      public themeService: ThemeService,
      private layout: LayoutService,
      public userService: UserService,
  ) {
  }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem;
      // Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
          item => item.type === 'icon'
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }

  toggleCollapse() {
    if (
        this.layoutConf.sidebarCompactToggle
    ) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: 'compact',
        sidebarCompactToggle: true
      });
    }
  }

  logout() {
    this.userService.logout();
  }
}
