import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout.service';
import { LanguagesService } from 'app/shared/services/language/languages.service';
import { NavigationService } from '../../services/navigation.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html'
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  egretThemes: any[] = [];
  currentLang = 'en';
  availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }]
  @Input() notificPanel;

  constructor(
      private layout: LayoutService,
      private navService: NavigationService,
      public themeService: ThemeService,
      public translate: TranslateService,
      public languagesService: LanguagesService
  ) {
  }

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    this.menuItemSub = this.navService.menuItems$
        .subscribe(res => {
          res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
          const limit = 4;
          const mainItems: any[] = res.slice(0, limit);
          if (res.length <= limit) {
            return this.menuItems = mainItems;
          }
          const subItems: any[] = res.slice(limit, res.length - 1)
          mainItems.push({
            name: 'More',
            type: 'dropDown',
            tooltip: 'More',
            icon: 'more_horiz',
            sub: subItems
          });
          this.menuItems = mainItems;
        })
  }

  ngOnDestroy() {
    this.menuItemSub.unsubscribe();
  }

  setLang(code) {
    this.languagesService.setLanguegeByCode(code);
  }

  changeTheme(theme) {
    this.layout.publishLayoutChange({matTheme: theme.name});
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }
}
