import { Pipe, PipeTransform } from '@angular/core';
/*
* A könnyebb debugoláshoz készült
*/
@Pipe({
  name: 'log'
})
export class LogPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    console.log(value);
    return value;
  }
}