import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'optionValueTranslate'
})
export class OtpionValueTranslatePipe implements PipeTransform {
constructor(private translate: TranslateService) { }
  transform(value: any, options: any[], valueKey: string = 'value', translateKey: string = 'name_key', ): any {
    if (options) {
      for (let index = 0; index < options.length; index++) {
        if (options[index][valueKey] == value) {
          return this.translate.instant(options[index][translateKey]);
        }
      }
    }

    return value;
  }
}

