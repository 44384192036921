import { NgModule, ErrorHandler, Injector } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { UsersService } from './shared/services/users.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ColumnTemplateService } from './shared/services/column-template.service';
import { TokenInterceptor } from './shared/services/token.interceptor';
import { UserService } from './shared/services/user.service';
import { LanguagesService } from './shared/services/language/languages.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ModelEditorService } from './shared/services/model-editor.service';
import { SchoolsService } from './shared/services/schools.service';
import { TagsService } from './shared/services/tags.service';
import { GroupsService } from './shared/services/groups.service';
import { AssetsService } from './shared/services/assets.service';
import { CustomReuseStrategy } from './shared/helpers/route-reuse-strategy';
import { SchoolsAdmissionService } from './shared/services/schools-admission.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxDatatableModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    RouterModule.forRoot(rootRouterConfig, {useHash: false}),
    MatIconModule
  ],
  declarations: [AppComponent],
  providers: [
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: RouteReuseStrategy, useClass: CustomReuseStrategy},
    ModelEditorService,
    LanguagesService,
    ColumnTemplateService,
    UserService,
    UsersService,
    SchoolsService,
    TagsService,
    GroupsService,
    AssetsService,
    SchoolsAdmissionService
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent]
})
export class AppModule {

  public static injector: Injector;
  public static snackBar: MatSnackBar;

  public static showErrorMessage(message: string) {
    AppModule.snackBar.open(message, 'Bezárás', {
      verticalPosition: 'top',
      duration: 2000,
    });
  }

  public static showMessage(message: string, action: string = null, config?: MatSnackBarConfig) {
    AppModule.snackBar.open(message, action, Object.assign({
      verticalPosition: 'top',
      duration: 2000,
    }, config));
  }

  constructor(injector: Injector, private languagesService: LanguagesService) {
    AppModule.injector = injector;
    AppModule.snackBar = injector.get(MatSnackBar);
    this.languagesService.detectLanguage(true);
  }
}
