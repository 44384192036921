import { Input, Directive, TemplateRef, AfterViewInit, ElementRef } from '@angular/core';
import { ColumnTemplateService } from '../services/column-template.service';

/*
*  A direktívával lehet megjeleölni a táblázat oszlop templatejeit
*/
@Directive({
  selector: 'ng-template[appColumnTemplate]'
})
export class ColumnTemplateDirective implements AfterViewInit {
  @Input('appColumnTemplate') templateName: string;

  constructor(public service: ColumnTemplateService, private ref: TemplateRef<any>) {
  }

  ngAfterViewInit() {
    this.service.templates[this.templateName] = this.ref;
  }
}
