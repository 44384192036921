import { Injectable } from '@angular/core';
import { ListService } from './list/list.service';
import { SchoolAdmission, SchoolUser } from '../models/school-admission.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class SchoolsAdmissionService extends ListService<SchoolAdmission> {
  url: string = 'schoolUsers';

  updateSchoolUser(schoolUser: SchoolUser): Observable<SchoolAdmission> {
    return this.http.put<SchoolAdmission>(environment.apiURL + this.url, schoolUser);
  }
}
