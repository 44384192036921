// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  apiURL_o: 'http://arra-backend.test.kodolj.hu/api/api-admin/v1/',
  tokenURL_o: 'http://arra-backend.test.kodolj.hu/api/oauth/token',
  apiBaseUrl_o: 'http://arra-backend.test.kodolj.hu/api/',
  apiURL: 'https://api.arra.app/api/api-admin/v1/',
  tokenURL: 'https://api.arra.app/api/oauth/token',
  apiBaseUrl: 'https://api.arra.app/api/',

  mediaURL: '',

  // A rendszer ilyen néven (kulcsal) menti a belépett user adatait
  userKey: 'arra_user',
  // A rendszer ilyen néven (kulcsal) menti a bejelentkezéskor kapott access tokent
  accessTokenKey: 'arra_token',
  // A rendszer ilyen néven (kulcsal) menti a refresh tokent
  refreshTokenKey: 'arra_token_refresh',

  // OAuth adatok
  clientId: 2,
  clientSecret: 'ARRAiDOFTjDNxRrUNdRYRyH9ECgb63MgRyGx3YdR',


};
