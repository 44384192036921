import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[focusCheck]'
})
export class TrackFocusDirective {

  @Output() clickOutside = new EventEmitter();

  constructor(private _elementRef: ElementRef) {
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (clickedInside) {
      this.clickOutside.emit(false);
    } else {
      this.clickOutside.emit(true);
    }
  }

}
