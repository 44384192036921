import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'app/shared/models/language.model';
import { Observable } from 'rxjs';

export const UnknownLanguage = {
  name: 'Unknown', code: undefined, flag: undefined
};

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {

  language: Language;
  languageChanged: EventEmitter<any>;

  languages: Language[] = [
    { name: 'Magyar', code: 'hu', flag: 'flag-icon-hu'},
    { name: 'English', code: 'en', flag: 'flag-icon-us'},
  ];

  constructor(public http: HttpClient, private translate: TranslateService) {
    this.languageChanged = new EventEmitter<any>();
  }

  detectLanguage(change: boolean = false): any {
    let userLang = navigator.language || (navigator as any).userLanguage;
    if (userLang.indexOf('-') !== -1) {
      userLang = userLang.split('-')[0];
    }

    let lang = this.getLanguageByCode(userLang);
    if (change && (lang.code != UnknownLanguage.code)) {
      this.setLanguage(lang);
    } else {
      this.setLanguage(this.languages[0]);
      lang = this.languages[0];
    }

    return lang;
  }

  getLanguage(): Language|null {
    return this.language;
  }

  getLanguageByCode(code): Language {
    const result = UnknownLanguage;

    for (let index = 0; index < this.languages.length; index++) {
      if (code === this.languages[index].code) {
        result.name = this.languages[index].name;
        result.flag = this.languages[index].flag;
        break;
      }
    }

    return result;
  }

  setLanguage(lang) {
    this.language = lang;
    this.translate.use(lang.code).subscribe(
      () => {
        this.languageChanged.emit(lang);
      }
    );
  }

  setLanguegeByCode(code): boolean {
    let result = false;

    for (let index = 0; index < this.languages.length; index++) {
      if (code == this.languages[index].code) {
        result = true;
        this.setLanguage(this.languages[index]);
        break;
      }
    }
    return result;
  }

  compareLanguages(lang1, lang2): boolean {
    return (lang1 && lang2) ? (lang1.code == lang2.code) : (lang1 === lang2);
  }

  languageChange(): Observable<Language|null> {
    return this.languageChanged.asObservable();
  }
}
