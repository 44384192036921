import { Injectable } from '@angular/core';
import { ListService } from './list/list.service';
import { School, SchoolUserResponse } from '../models/school.model';
import { Observable } from 'rxjs';
import { ListResponse } from '../models/api/list.response';
import { environment } from '../../../environments/environment';

/*
* Az iskolákkal kapcsolatos szerver kommunikációért felel
*/
@Injectable()
export class SchoolsService extends ListService<School> {
  url: string = 'schools';

  getSchoolUserList(schoolId: number): Observable<ListResponse<SchoolUserResponse>> {
    return this.http.get<ListResponse<SchoolUserResponse>>(environment.apiURL + this.url + '/' + schoolId + '/users');
  }
}
