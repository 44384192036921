import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptor,
  HttpClient
} from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UserService } from './user.service';

/*
* API kérésekhez adja hozzá a JWT-t.
* A lejárt JWT-t újítja meg a refresh tokennel.
* Ha nem sikerül akkor kijelentkeztet.
*/
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  tokenRefreshInProgress: boolean = false;
  tokenRefreshing: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router, private userService: UserService, private http: HttpClient) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(request))
      .pipe(catchError((err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return throwError(err);
          }

          if (this.tokenRefreshInProgress) {
            return this.tokenRefreshing.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addToken(request)))
            );
          } else {
            this.tokenRefreshInProgress = true;

            this.tokenRefreshing.next(null);

            const data = {
              client_id: environment.clientId,
              client_secret: environment.clientSecret,
              refresh_token: this.userService.getRefreshToken()
            };
            return this.http.put(environment.tokenURL, data).pipe(
              switchMap((response: any) => {
                this.userService.saveData(response);
                this.tokenRefreshInProgress = false;

                this.tokenRefreshing.next(response);
                return next.handle(this.addToken(request));
              }),
              catchError((error: any) => {
                this.tokenRefreshInProgress = false;
                this.userService.logout();
                return throwError(error);
              })
            );
          }
        }
      })));
  }

  addToken(request: HttpRequest<any>): HttpRequest<any> {
    const headers: any = {};

    if (this.userService.getToken() != null) {
      headers['Authorization'] = 'Bearer ' + this.userService.getToken();
    }

    return request.clone({
      setHeaders: headers
    });
  }
}
