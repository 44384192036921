import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IEntity } from '../../models/base-entity';
import { ListResponse } from '../../models/api/list.response';
import { ItemResponse } from '../../models/api/item.response';

@Injectable()
export abstract class ListService<TModel extends IEntity>  {

  public abstract url: string;

  constructor(public http: HttpClient) {}

  getList(): Observable<ListResponse<TModel>> {
    return this.http.get<ListResponse<TModel>>(environment.apiURL + this.url + '?limit=-1');
  }

  getById(id: number): Observable<ItemResponse<TModel>> {
    return this.http.get<ItemResponse<TModel>>(environment.apiURL + this.url + '/' + id);
  }

  create(data): Observable<ItemResponse<TModel>> {
    return this.http.post<ItemResponse<TModel>>(environment.apiURL + this.url, data);
  }

  update(data: TModel): Observable<ItemResponse<TModel>> {
    return this.http.put<ItemResponse<TModel>>(environment.apiURL + this.url + '/' + data.id, data);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(environment.apiURL + this.url + '/' + id);
  }
}
