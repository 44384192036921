import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { ListService } from './list/list.service';

/*
* Userrel kapcsolatos szerver kommunikációért felel
*/
@Injectable()
export class UsersService extends ListService<User> {
  url: string = 'users';
}
