import { Input, Directive, ElementRef, HostListener } from '@angular/core';

/*
/  A direktívával meg lehet jelölni img tageket hogy,
/  ha nem tölt be a kép akkor megadható egy helyettesítő kép 
/
/  használata: <img src="imagePath" appImageFallback="fallbackImagePath">
*/
@Directive({
  selector: 'img[appImageFallback]'
})
export class ImageFallbackDirective {
  @Input() appImageFallback: string;

  onerror: boolean = false;
  constructor(private ref: ElementRef) {}

// Ha nem sikerül betölteni a képet akkor kicseréljük a fallback képre
  @HostListener('error')
  fallback() {
    if (!this.onerror) {
      this.onerror = true;
      const element: HTMLImageElement = this.ref.nativeElement;
      element.src = this.appImageFallback;
    }
  }
}
