import { Injectable } from '@angular/core';
import { ListService } from './list/list.service';
import { Tag } from '../models/tag.model';

/*
* A tagekkel kapcsolatos szerver kommunikációért felel
*/
@Injectable()
export class TagsService extends ListService<Tag> {
  url: string = 'tags';
}
