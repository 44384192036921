<div class="sidebar-panel">
  <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
    <div class="sidebar-hold">

      <!-- Sidebar user -->
      <div class="app-logo-container">
        <div class="app-logo">
          <img src="assets/images/arra-logo.png" class="mat-elevation-z1" alt="">
        </div>
      </div>
      <!-- Navigation -->
      <app-sidenav
        [items]="menuItems"
        [hasIconMenu]="hasIconTypeMenuItem"
        [iconMenuTitle]="navService.iconTypeMenuTitle"
      ></app-sidenav>

      <div class="sidebar-version">
        <a target="_blank">v{{ version }}</a>
      </div>
    </div>
  </div>
</div>
