import { Injectable } from '@angular/core';
export const ColumnTemplateNames = {
  'AVATAR_CELL_TEMPLATE': 'AVATAR_CELL_TEMPLATE',
};

/*
* Ebben a serviceben vannak letárolva név szerint az előre elkészített cella templatek, így globálisan elérhetők a servicen keresztül
*/
@Injectable({
  providedIn: 'root',
})
export class ColumnTemplateService {

  templates: Object = new Object();
  constructor() {
  }

  templateExist(name): boolean {
    return this.templates.hasOwnProperty(name);
  }
}
